export const schools = {
    "A.E. Cross School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Erin Bennett",
        "principalEmail": "ECBennett@cbe.ab.ca"
    },
    "Abbeydale School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Jaydon Derdall",
        "principalEmail": "abbeydale@cbe.ab.ca"
    },
    "Acadia School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Lisa Bartel",
        "principalEmail": "labartel@cbe.ab.ca"
    },
    "Alex Munro School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Tamra Wilcox",
        "principalEmail": "alexmunro@cbe.ab.ca"
    },
    "Alexander Ferguson School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Helen Sharpe",
        "principalEmail": "hcsharpe@cbe.ab.ca"
    },
    "All Boys Program": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Erik Sprong",
        "principalEmail": "eksprong@cbe.ab.ca"
    },
    "Altadore School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Kelly Christopher",
        "principalEmail": "kdchristophe@cbe.ab.ca"
    },
    "Alternative High School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Don Barbor",
        "principalEmail": "BDBarbor@cbe.ab.ca"
    },
    "Andrew Sibbald School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Jill Scarrow",
        "principalEmail": "jcscarrow@cbe.ab.ca"
    },
    "Annie Foote School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Tania Brown",
        "principalEmail": "tmbrown@cbe.ab.ca"
    },
    "Annie Gale School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Tammy Quiring",
        "principalEmail": "anniegale@cbe.ab.ca"
    },
    "Arbour Lake School": {
        "ward": 2,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7945",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Nancy Lisi",
        "principalEmail": "nmlisi@cbe.ab.ca"
    },
    "Auburn Bay School": {
        "ward": 12,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Lori Cullen",
        "principalEmail": "auburnbay@cbe.ab.ca"
    },
    "Balmoral School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Rakhee Vijairaghavan",
        "principalEmail": "rjvijairaghavan@cbe.ab.ca"
    },
    "Banff Trail School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Ryan Turner",
        "principalEmail": "banfftrail@cbe.ab.ca"
    },
    "Banting and Best School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Payal Tuli",
        "principalEmail": "patuli@cbe.ab.ca"
    },
    "Battalion Park School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "T Tallerico",
        "principalEmail": "tstallerico@cbe.ab.ca"
    },
    "Bayside School": {
        "ward": 12,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Darby McAsey",
        "principalEmail": "djmcasey@cbe.ab.ca"
    },
    "Beddington Heights School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "S. Ross",
        "principalEmail": "saross@cbe.ab.ca"
    },
    "Belfast School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Meagan Lundgren",
        "principalEmail": "mllundgren@cbe.ab.ca"
    },
    "Belvedere Parkway School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7929",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Jane Gibson",
        "principalEmail": "jegibson@cbe.ab.ca"
    },
    "Bishop Pinkham School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Rishma Hajee",
        "principalEmail": "rnhajee@cbe.ab.ca"
    },
    "Bob Edwards School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Alexandros Fotopoulos",
        "principalEmail": "alfotopoulos@cbe.ab.ca"
    },
    "Bowcroft School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Meike Thomson",
        "principalEmail": "methomson@cbe.ab.ca"
    },
    "Bowness High School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7931",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Jana MacDonald",
        "principalEmail": "jlmacdonald@cbe.ab.ca"
    },
    "Braeside School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Melanie Bennett",
        "principalEmail": "mabennett@cbe.ab.ca"
    },
    "Branton School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Sebastien Picard",
        "principalEmail": "sepicard@cbe.ab.ca"
    },
    "Brentwood School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Rudy Stein",
        "principalEmail": "brentwood@cbe.ab.ca"
    },
    "Briar Hill School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Elan La Montagne",
        "principalEmail": "erlamontagne@cbe.ab.ca"
    },
    "Bridlewood School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Eric Tourigny",
        "principalEmail": "ejtourigny@cbe.ab.ca"
    },
    "Buchanan School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Jeff Lailey",
        "principalEmail": "jhlailey@cbe.ab.ca"
    },
    "Buffalo Rubbing Stone School": {
        "ward": 3,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Allison Bobenic",
        "principalEmail": "aebobenic@cbe.ab.ca"
    },
    "Cambrian Heights School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Kevin Whitton",
        "principalEmail": "kmwhitton@cbe.ab.ca"
    },
    "Canyon Meadows School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Lilien Perera Perez",
        "principalEmail": "canyonmeadows@cbe.ab.ca"
    },
    "Capital Hill School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Hilary Wright",
        "principalEmail": "CapitolHill@cbe.ab.ca"
    },
    "Cappy Smart School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Rachel MacDonald",
        "principalEmail": "CappySmart@cbe.ab.ca"
    },
    "Captain John Palliser School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Oliver Fisher",
        "principalEmail": "ojfisher@cbe.ab.ca"
    },
    "Captain Nichola Goddard School": {
        "ward": 3,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Ross Bushell",
        "principalEmail": "rjbushell@cbe.ab.ca"
    },
    "Catherine Nichols Gunn School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Linda Doyle",
        "principalEmail": "lmdoyle@cbe.ab.ca"
    },
    "Cecil Swanson": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "A Topp",
        "principalEmail": "adtopp@cbe.ab.ca"
    },
    "Cedarbrae School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Leanne Murphy",
        "principalEmail": "cedarbrae@cbe.ab.ca"
    },
    "Centennial High School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Joe Sturgeon",
        "principalEmail": "jisturgeon@cbe.ab.ca"
    },
    "Central Memorial High School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Anne Kromm",
        "principalEmail": "akkromm@cbe.ab.ca"
    },
    "Chaparral School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Anica Robinson",
        "principalEmail": "AGRobinson@cbe.ab.ca"
    },
    "Chief Justice Milvain School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Aubrey Fletcher",
        "principalEmail": "chiefjusticemilvain@cbe.ab.ca"
    },
    "Children's Village School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Kristi Jardine",
        "principalEmail": "KMJardine@cbe.ab.ca"
    },
    "Chinook Park School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Guillaume Comeau",
        "principalEmail": "gucomeau@cbe.ab.ca"
    },
    "Chris Akkerman School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Leanne Bettesworth",
        "principalEmail": "chrisakkerman@cbe.ab.ca"
    },
    "Christine Meikle School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Alison Van Rosendaal",
        "principalEmail": "avrosendaal@cbe.ab.ca"
    },
    "Citadel Park School": {
        "ward": 2,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7945",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Carrie Edwards",
        "principalEmail": "citadel@cbe.ab.ca"
    },
    "Clarence Sansom School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Gayleena Clark",
        "principalEmail": "gmclark@cbe.ab.ca"
    },
    "Collingwood School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Don Dornan",
        "principalEmail": "collingwood@cbe.ab.ca"
    },
    "Colonel Irving School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Bea Ko",
        "principalEmail": "bsko@cbe.ab.ca"
    },
    "Colonel J. Fred Scott School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Mr. Robinson",
        "principalEmail": "coloneljfredscott@cbe.ab.ca"
    },
    "Colonel Macleod School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Darren Prather",
        "principalEmail": "dmprather@cbe.ab.ca"
    },
    "Colonel Sanders School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Alexandra Caso-Gustafson",
        "principalEmail": "Colonelsanders@cbe.ab.ca"
    },
    "Colonel Walker School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Kathryn Jones",
        "principalEmail": "kejones@cbe.ab.ca"
    },
    "Connaught School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Nicole Bezugley",
        "principalEmail": "nmbezugley@cbe.ab.ca"
    },
    "Copperfield School": {
        "ward": 12,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Mike Magowan",
        "principalEmail": "copperfield@cbe.ab.ca"
    },
    "Coventry Hills School": {
        "ward": 3,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Benita Dalton",
        "principalEmail": "bfdalton@cbe.ab.ca"
    },
    "Cranston School": {
        "ward": 12,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Lone Just-Tuff",
        "principalEmail": "cranston@cbe.ab.ca"
    },
    "Crescent Heights High School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "J Leavitt",
        "principalEmail": "jsleavitt@cbe.ab.ca"
    },
    "Crossing Park School": {
        "ward": 5,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Andrew Bews",
        "principalEmail": "acbews@cbe.ab.ca"
    },
    "Dalhousie School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Katrin Lusignan",
        "principalEmail": "kalusignan@cbe.ab.ca"
    },
    "David Thompson School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Chris Keuben",
        "principalEmail": "cjkeuben@cbe.ab.ca"
    },
    "Deer Run School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Shawna Weir",
        "principalEmail": "slrabeyweir@cbe.ab.ca"
    },
    "Douglas Harkness School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Jennifer George",
        "principalEmail": "DouglasHarkness@cbe.ab.ca"
    },
    "Douglasdale School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Heather McKay",
        "principalEmail": "douglasdale@cbe.ab.ca"
    },
    "Dr. E.P. Scarlet High School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Shari Goodfellow",
        "principalEmail": "slgoodfellow@cbe.ab.ca"
    },
    "Dr. E.W. Coffin School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Christopher Hooper",
        "principalEmail": "cchooper@cbe.ab.ca"
    },
    "Dr. Freda Miller School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Michael Craig",
        "principalEmail": "mscraig@cbe.ab.ca"
    },
    "Dr. George Stanley School": {
        "ward": 12,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Chantel Malette",
        "principalEmail": "csmalette@cbe.ab.ca"
    },
    "Dr. Gladys McKelvie Egbert School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Mandy Hambidge",
        "principalEmail": "mjhambidge@cbe.ab.ca"
    },
    "Dr. Gordon Higgins School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Maureen Hurst",
        "principalEmail": "drgordonhiggins@cbe.ab.ca"
    },
    "Dr. J.K. Mulloy School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Terrie Lay",
        "principalEmail": "tdlay@cbe.ab.ca"
    },
    "Dr. Martha Cohen School": {
        "ward": 12,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "J Hartl",
        "principalEmail": "drmarthacohen@cbe.ab.ca"
    },
    "Dr. Oakley School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Denise Nimmo",
        "principalEmail": "dmnimmo@cbe.ab.ca"
    },
    "Dr. Roberta Bondar School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Evelyne Lamontagne",
        "principalEmail": "ejlamontagne@cbe.ab.ca"
    },
    "Dr. Townsend School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Jennifer Edmondson-Neily",
        "principalEmail": "jlneily@cbe.ab.ca"
    },
    "Earl Grey School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Matthew Lowe",
        "principalEmail": "mhlowe@cbe.ab.ca"
    },
    "Edgemont School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Lori Holford",
        "principalEmail": "llholford@cbe.ab.ca"
    },
    "Elbow Park School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Jason Fech",
        "principalEmail": "jwfech@cbe.ab.ca"
    },
    "Elboya School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Aliki Avdicos",
        "principalEmail": "alavdicos@cbe.ab.ca"
    },
    "Emily Follensbee School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Jennifer Hart",
        "principalEmail": "jmhart@cbe.ab.ca"
    },
    "Eric Harvey School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7933",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Ben Strand",
        "principalEmail": "bcstrand@cbe.ab.ca"
    },
    "Erin Woods School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Su-Anne Anderson",
        "principalEmail": "slanderson@cbe.ab.ca"
    },
    "Ernest Manning High School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Mike Wilson",
        "principalEmail": "mfwilson@cbe.ab.ca"
    },
    "Ernest Morrow School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Cheri Schwartz",
        "principalEmail": "clschwartz@cbe.ab.ca"
    },
    "Ethel M. Johnson School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Marina Clark",
        "principalEmail": "mcclark@cbe.ab.ca"
    },
    "Eugene Coste School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Andrea Riquelme",
        "principalEmail": "anriquelme@cbe.ab.ca"
    },
    "Evergreen School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Angela McPhee",
        "principalEmail": "ACMcphee@cbe.ab.ca"
    },
    "F.E. Osbourne School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7934",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Lesley Scullion",
        "principalEmail": "ljscullion@cbe.ab.ca"
    },
    "Fairview School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Therese McCoag",
        "principalEmail": "tamccoag@cbe.ab.ca"
    },
    "Falconridge School": {
        "ward": 5,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Jesse Hillas",
        "principalEmail": "Falconridge@cbe.ab.ca"
    },
    "Fish Creek School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Mike Hebenton",
        "principalEmail": "mdhebenton@cbe.ab.ca"
    },
    "Forest Lawn High School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Matt Fell",
        "principalEmail": "mcfell@cbe.ab.ca"
    },
    "G.W. Skene School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Christina Tuttle",
        "principalEmail": "cmtuttle@cbe.ab.ca"
    },
    "Georges P. Vanier School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Chad Edmonds",
        "principalEmail": "caedmonds@cbe.ab.ca"
    },
    "Glamorgan School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Frederick Mattersdorfer",
        "principalEmail": "glamorgan@cbe.ab.ca"
    },
    "Glenbrook School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Kathy Andrews",
        "principalEmail": "kjandrews@cbe.ab.ca"
    },
    "Glendale School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Kristin Dahl",
        "principalEmail": "kldahl@cbe.ab.ca"
    },
    "Glenmeadows School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Ray Suarez",
        "principalEmail": "resuarez@cbe.ab.ca"
    },
    "Grant MacEwan School": {
        "ward": 5,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Grant McPhail",
        "principalEmail": "GrantMacEwan@cbe.ab.ca"
    },
    "Griffith Woods School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Jennifer Batycky",
        "principalEmail": "jmbatycky@cbe.ab.ca"
    },
    "Guy Weadick School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Pamela Bates",
        "principalEmail": "GuyWeadick@cbe.ab.ca"
    },
    "H.D. Cartwright School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Jim Parent",
        "principalEmail": "hdcartwright@cbe.ab.ca"
    },
    "Harold Panabaker School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Shelly Li",
        "principalEmail": "shli@cbe.ab.ca"
    },
    "Haultain Memorial School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Mrs. Young",
        "principalEmail": "mayoung@cbe.ab.ca"
    },
    "Hawkwood School": {
        "ward": 2,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7945",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Mike MacDonald",
        "principalEmail": "mpmacdonald@cbe.ab.ca"
    },
    "Haysboro School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Sandra Traquair",
        "principalEmail": "sjtraquair@cbe.ab.ca"
    },
    "Henry Wisewood High School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Aileen Smyth",
        "principalEmail": "arsmyth@cbe.ab.ca"
    },
    "Hidden Valley School": {
        "ward": 3,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Allison Hull",
        "principalEmail": "amhull@cbe.ab.ca"
    },
    "Highwood School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Christine Cheung",
        "principalEmail": "highwood@cbe.ab.ca"
    },
    "Hillhurst School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Karen Pope",
        "principalEmail": "kjpope@cbe.ab.ca"
    },
    "Hugh A. Bennett School": {
        "ward": 5,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Donna Diaz",
        "principalEmail": "dmdiaz@cbe.ab.ca"
    },
    "Huntington Hills School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Trevor Layden",
        "principalEmail": "TTLayden@cbe.ab.ca"
    },
    "Ian Bazalgette School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Brad Aldridge",
        "principalEmail": "braaldridge@cbe.ab.ca"
    },
    "Jack James High School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Jennifer Gorkoff",
        "principalEmail": "jlgorkoff@cbe.ab.ca"
    },
    "James Fowler High School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "K Johnson",
        "principalEmail": "kejohnson@cbe.ab.ca"
    },
    "James Short Memorial School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ac",
        "areaOfficePhone": "403-777-6820",
        "principal": "Mrs. Williams",
        "principalEmail": "JamesShortMemorial@cbe.ab.ca"
    },
    "Janet Johnstone School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Bobbi Kivi",
        "principalEmail": "blkivi@cbe.ab.ca"
    },
    "Jennie Elliott School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Stephen Ogilvie",
        "principalEmail": "JennieElliott@cbe.ab.ca"
    },
    "Joane Cardinal-Schubert High School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Gary Tink",
        "principalEmail": "gctink@cbe.ab.ca"
    },
    "John G. Diefenbaker High School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Jason Arrell",
        "principalEmail": "jsarrell@cbe.ab.ca"
    },
    "John Ware School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Tanis Greenlaw",
        "principalEmail": "tmgreenlaw@cbe.ab.ca"
    },
    "Keeler School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Kenny Yeung",
        "principalEmail": "keyeung@cbe.ab.ca"
    },
    "Kenneth D. Taylor School": {
        "ward": 2,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7945",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Todd Faunt",
        "principalEmail": "MTFaunt@cbe.ab.ca"
    },
    "Killarney School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Lea Sherwood",
        "principalEmail": "Killarney@cbe.ab.ca"
    },
    "King George School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Shafina Dharamsi",
        "principalEmail": "kinggeorge@cbe.ab.ca"
    },
    "Lake Bonavista School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Jennifer Rooke",
        "principalEmail": "jlrooke@cbe.ab.ca"
    },
    "Lakeshore School": {
        "ward": 12,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Ian Fero",
        "principalEmail": "itfero@cbe.ab.ca"
    },
    "Le Roi Daniels School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Andrea Taylor",
        "principalEmail": "ADTaylor@cbe.ab.ca"
    },
    "Lester B. Pearson High School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "K Chee",
        "principalEmail": "kechee@cbe.ab.ca"
    },
    "Lord Beaverbrook High School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Darren Whitbread",
        "principalEmail": "ddwhitbread@cbe.ab.ca"
    },
    "Louis Riel School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Matthew Powter",
        "principalEmail": "mdpowter@cbe.ab.ca"
    },
    "Louise Dean School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Helen Colbourne",
        "principalEmail": "hecolbourne@cbe.ab.ca"
    },
    "Mahogany School": {
        "ward": 12,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Tracy Kohan",
        "principalEmail": "tckohan@cbe.ab.ca"
    },
    "Manmeet Singh Bhullar School": {
        "ward": 5,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "C Zelmer",
        "principalEmail": "Manmeetsinghbhullar@cbe.ab.ca"
    },
    "Maple Ridge School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "David Laidlaw",
        "principalEmail": "drlaidlaw@cbe.ab.ca"
    },
    "Marion Carson School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7935",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "D. Turner",
        "principalEmail": "MarionCarson@cbe.ab.ca"
    },
    "Marlborough School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Kathleen Power",
        "principalEmail": "marlborough@cbe.ab.ca"
    },
    "Marshall Springs School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Kevin Godfrey",
        "principalEmail": "marshallsprings@cbe.ab.ca"
    },
    "Mayland Heights School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Darcy Wiebe",
        "principalEmail": "dawiebe@cbe.ab.ca"
    },
    "McKenzie Highlands School": {
        "ward": 12,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Daryl Martin",
        "principalEmail": "dwmartin@cbe.ab.ca"
    },
    "McKenzie Lake School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Dayna McComish",
        "principalEmail": "dmmccomish@cbe.ab.ca"
    },
    "McKenzie Towne School": {
        "ward": 12,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Lorraine Slater",
        "principalEmail": "LCSlater@cbe.ab.ca"
    },
    "Midnapore School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Shelly Pynoo",
        "principalEmail": "sgpynoo@cbe.ab.ca"
    },
    "MidSun School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Sherri Chatani",
        "principalEmail": "slchatani@cbe.ab.ca"
    },
    "Monterey Park School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Carrie Dayman",
        "principalEmail": "montereypark@cbe.ab.ca"
    },
    "Mount Royal School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Bradey Thompson",
        "principalEmail": "bjthompson@cbe.ab.ca"
    },
    "Mount View School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Tejinder Kang",
        "principalEmail": "tkkang@cbe.ab.ca"
    },
    "Mountain Park School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "David Sparrow",
        "principalEmail": "dgsparrow@cbe.ab.ca"
    },
    "Nellie McClung School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Darren Dyck",
        "principalEmail": "dkdyck@cbe.ab.ca"
    },
    "Nelson Mandela High School": {
        "ward": 5,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Matt Armstrong",
        "principalEmail": "nelsonmandelahigh@cbe.ab.ca"
    },
    "New Brighton School": {
        "ward": 12,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Patrick Murphy",
        "principalEmail": "pjmurphy@cbe.ab.ca"
    },
    "Nickle School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "C Flaman",
        "principalEmail": "ceflaman@cbe.ab.ca"
    },
    "Niitsitapi Learning Centre": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Allison Field",
        "principalEmail": "alfield@cbe.ab.ca"
    },
    "North Haven School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Cat Gammell",
        "principalEmail": "crgammell@cbe.ab.ca"
    },
    "Northern Lights School": {
        "ward": 3,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Michelle Brinson",
        "principalEmail": "mlbrinson@cbe.ab.ca"
    },
    "Nose Creek School": {
        "ward": 3,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Tammy Jeffrey",
        "principalEmail": "tljeffrey@cbe.ab.ca"
    },
    "O.S. Geiger School": {
        "ward": 5,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Mary Miller",
        "principalEmail": "mamiller@cbe.ab.ca"
    },
    "Olympic Heights School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Trevor Barkley",
        "principalEmail": "tsbarkley@cbe.ab.ca"
    },
    "Panorama Hills School": {
        "ward": 3,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Allison Paull",
        "principalEmail": "atpaull@cbe.ab.ca"
    },
    "Patrick Airlie School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Mary-Elizabeth Kaiser",
        "principalEmail": "mekaiser@cbe.ab.ca"
    },
    "Penbrooke Meadows School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Karon Hayes",
        "principalEmail": "kvhayes@cbe.ab.ca"
    },
    "Peter Lougheed School": {
        "ward": 5,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Kathy Salmon",
        "principalEmail": "klsalmon@cbe.ab.ca"
    },
    "Piitoayis Family School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Crystal Good Rider",
        "principalEmail": "CLGoodRider@cbe.ab.ca"
    },
    "Pineridge School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Leslie Goodwin",
        "principalEmail": "Pineridge@cbe.ab.ca"
    },
    "Prairie Sky School": {
        "ward": 5,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Mona Harswinder Bains",
        "principalEmail": "hkbains@cbe.ab.ca"
    },
    "Prince of Wales School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Shelly Gelfand",
        "principalEmail": "shgelfand@cbe.ab.ca"
    },
    "Queen Elizabeth High School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Claire Haney",
        "principalEmail": "queenelizabethjrsr@cbe.ab.ca"
    },
    "Queen Elizabeth School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "L McConnell",
        "principalEmail": "lamcconnell@cbe.ab.ca"
    },
    "R.T. Alderman School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Brenda Lewis",
        "principalEmail": "bclewis@cbe.ab.ca"
    },
    "Radison Park School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Tracy Drefko",
        "principalEmail": "tjdrefko@cbe.ab.ca"
    },
    "Ramsay School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Shane Turner",
        "principalEmail": "saturner@cbe.ab.ca"
    },
    "Ranchlands School": {
        "ward": 2,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7945",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Steven Wigglesworth",
        "principalEmail": "sewiggleswor@cbe.ab.ca"
    },
    "Richmond School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Mary Anne Fullerton",
        "principalEmail": "mafullerton@cbe.ab.ca"
    },
    "Rideau Park School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Travis Robertson",
        "principalEmail": "rideaupark@cbe.ab.ca"
    },
    "Riverbend School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Mike Black",
        "principalEmail": "mcblack@cbe.ab.ca"
    },
    "Riverside School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Nancy Gillott",
        "principalEmail": "nlgillott@cbe.ab.ca"
    },
    "Robert Thirsk High School": {
        "ward": 2,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7945",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Teresa Martin",
        "principalEmail": "tamartin@cbe.ab.ca"
    },
    "Robert Warren School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Colin Haydu",
        "principalEmail": "cjhaydu@cbe.ab.ca"
    },
    "Roland Michener School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Cindy Palmer",
        "principalEmail": "ccpalmer@cbe.ab.ca"
    },
    "Ron Southern School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Zoe Serediuk",
        "principalEmail": "zbserediuk@cbe.ab.ca"
    },
    "Rosedale School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Susan Faulkner",
        "principalEmail": "smfaulkner@cbe.ab.ca"
    },
    "Rosemont School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Michelle Chastko",
        "principalEmail": "mjchastko@cbe.ab.ca"
    },
    "Royal Oak School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7936",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Gord Hamby",
        "principalEmail": "ghhamby@cbe.ab.ca"
    },
    "Rundle School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Chris Southworth",
        "principalEmail": "cnsouthworth@cbe.ab.ca"
    },
    "Saddle Ridge School": {
        "ward": 5,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Sandra Levesque",
        "principalEmail": "slevesque@cbe.ab.ca"
    },
    "Sam Livingston School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Sanghamitra Dhar McKenty",
        "principalEmail": "SBDhar@cbe.ab.ca"
    },
    "Samuel W. Shaw School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Jason Wilcox",
        "principalEmail": "jswilcox@cbe.ab.ca"
    },
    "Scenic Acres School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7937",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Bill Garner",
        "principalEmail": "bjgarner@cbe.ab.ca"
    },
    "Senator Patrick Burns School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Harold Ludwigsen",
        "principalEmail": "haludwigsen@cbe.ab.ca"
    },
    "Sherwood School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "K Munroe",
        "principalEmail": "kmmunroe@cbe.ab.ca"
    },
    "Sibylla Kiddle School": {
        "ward": 12,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Brad Emery",
        "principalEmail": "bremery@cbe.ab.ca"
    },
    "Silver Springs School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7938",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Michelle Ryskamp",
        "principalEmail": "mcryskamp@cbe.ab.ca"
    },
    "Simon Fraser School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "L. Lailey",
        "principalEmail": "lllailey@cbe.ab.ca"
    },
    "Simons Valley School": {
        "ward": 3,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Christopher Snow",
        "principalEmail": "chsnow@cbe.ab.ca"
    },
    "Sir John A. MacDonald School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Michelle Hornby",
        "principalEmail": "mahornby@cbe.ab.ca"
    },
    "Sir John Franklin School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Monica Wiersma",
        "principalEmail": "mnwiersma@cbe.ab.ca"
    },
    "Sir Wilfred Laurier School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Andrea Petroni",
        "principalEmail": "ampetroni@cbe.ab.ca"
    },
    "Sir Winston Churchill School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Arvin Rajan",
        "principalEmail": "arrajan@cbe.ab.ca"
    },
    "Somerset School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Nathan Bane",
        "principalEmail": "ndbane@cbe.ab.ca"
    },
    "Stanley Jones School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Ms. Bartlett",
        "principalEmail": "KDBartlett@cbe.ab.ca"
    },
    "Sunalta School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Christine McCrory",
        "principalEmail": "cnmccrory@cbe.ab.ca"
    },
    "Sundance School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Melanie Tindall",
        "principalEmail": "MATindall@cbe.ab.ca"
    },
    "Sunnyside School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Krista Schmitt",
        "principalEmail": "kaschmitt@cbe.ab.ca"
    },
    "Taradale School": {
        "ward": 5,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "J McLaren",
        "principalEmail": "Taradale@cbe.ab.ca"
    },
    "Ted Harrison School": {
        "ward": 5,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Leta Youck-McGowan",
        "principalEmail": "lmyouckmcgow@cbe.ab.ca"
    },
    "Terrace Road School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Tiffany Austin",
        "principalEmail": "terraceroad@cbe.ab.ca"
    },
    "Terry Fox School": {
        "ward": 5,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 4,
        "areaOfficeEmail": "Area4@cbe.ab.ca",
        "areaOfficePhone": "403-777-6233",
        "principal": "Leslie McRae",
        "principalEmail": "legmcrae@cbe.ab.ca"
    },
    "The Hamptons School": {
        "ward": 2,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7945",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "R. Williams",
        "principalEmail": "RMWilliams@cbe.ab.ca"
    },
    "Thomas B. Riley School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7940",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Darrin Moody",
        "principalEmail": "dbmoody@cbe.ab.ca"
    },
    "Thorncliffe School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Glen Steed",
        "principalEmail": "gcsteed@cbe.ab.ca"
    },
    "Tom Baines School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Mr. Siakaluk",
        "principalEmail": "djsiakaluk@cbe.ab.ca"
    },
    "Tuscany School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7941",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Patrick Tomczyk",
        "principalEmail": "pstomczyk@cbe.ab.ca"
    },
    "Twelve Mile Coulee School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7942",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Derek Rakowski",
        "principalEmail": "darakowski@cbe.ab.ca"
    },
    "University School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Jeremy Lang",
        "principalEmail": "jplang@cbe.ab.ca"
    },
    "Valley Creek School": {
        "ward": 3,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Sheilagh O'Dwyer",
        "principalEmail": "slodwyer@cbe.ab.ca"
    },
    "Valleyview School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Shawn Palmer",
        "principalEmail": "smpalmer@cbe.ab.ca"
    },
    "Varsity Acres School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7943",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Brooke Reilly",
        "principalEmail": "brreilly@cbe.ab.ca"
    },
    "Vincent Massey School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Mark Bylsma",
        "principalEmail": "msbylsma@cbe.ab.ca"
    },
    "Vista Heights School": {
        "ward": 10,
        "trustee": "Marilyn Dennis",
        "trusteeEmail": "madennis@cbe.ab.ca",
        "trusteePhone": "403-817-7930",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Mrs. Bose",
        "principalEmail": "kabose@cbe.ab.ca"
    },
    "W.O. Mitchell School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7944",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Erick Noriega",
        "principalEmail": "eenoriega@cbe.ab.ca"
    },
    "West Dalhousie School": {
        "ward": 4,
        "trustee": "Laura Hack",
        "trusteeEmail": "laahack@cbe.ab.ca",
        "trusteePhone": "403-817-7926",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "J. Chapman-Brown",
        "principalEmail": "jechapmanbro@cbe.ab.ca"
    },
    "West Dover School": {
        "ward": 9,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 3,
        "areaOfficeEmail": "Area3@cbe.ab.ca",
        "areaOfficePhone": "403-777-6820",
        "principal": "Roy Strum",
        "principalEmail": "rostrum@cbe.ab.ca"
    },
    "West Ridge School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Susan Coveyduck",
        "principalEmail": "secoveyduck@cbe.ab.ca"
    },
    "West Springs School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Lynn Sparks",
        "principalEmail": "elsparks@cbe.ab.ca"
    },
    "Western Canada High School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Carma Cornea",
        "principalEmail": "clcornea@cbe.ab.ca"
    },
    "Westgate School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Julie Muhlberger",
        "principalEmail": "jamuhlberger@cbe.ab.ca"
    },
    "Wildwood School": {
        "ward": 6,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Erica Rae",
        "principalEmail": "ebrae@cbe.ab.ca"
    },
    "William Aberhart High School": {
        "ward": 7,
        "trustee": "Patricia Bolger",
        "trusteeEmail": "pjbolger@cbe.ab.ca",
        "trusteePhone": "403-817-7927",
        "areaOffice": 2,
        "areaOfficeEmail": "Area2@cbe.ab.ca",
        "areaOfficePhone": "403-777-8720",
        "principal": "Christos Sagriotis",
        "principalEmail": "williamaberhart@cbe.ab.ca"
    },
    "William D. Pratt School": {
        "ward": 1,
        "trustee": "Dana Downey",
        "trusteeEmail": "dacdowney@cbe.ab.ca",
        "trusteePhone": "403-817-7945",
        "areaOffice": 1,
        "areaOfficeEmail": "Area1@cbe.ab.ca",
        "areaOfficePhone": "403-777-8710",
        "principal": "Janice Luchenski",
        "principalEmail": "jjluchenski@cbe.ab.ca"
    },
    "William Reid School": {
        "ward": 8,
        "trustee": "Susan Vukadinovic",
        "trusteeEmail": "suvakadinovic@cbe.ab.ca",
        "trusteePhone": "403-817-7932",
        "areaOffice": 7,
        "areaOfficeEmail": "Area7@cbe.ab.ac",
        "areaOfficePhone": "403-777-8750",
        "principal": "Christine Stiles",
        "principalEmail": "cestiles@cbe.ab.ca"
    },
    "William Roper Hull School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Michael McMahon",
        "principalEmail": "mamcmahon@cbe.ab.ca"
    },
    "Willow Park School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Dean Marklinger",
        "principalEmail": "djmarklinger@cbe.ab.ca"
    },
    "Wilma Hansen School": {
        "ward": 14,
        "trustee": "Charlene May",
        "trusteeEmail": "clmay@cbe.ab.ca",
        "trusteePhone": "403-817-7919",
        "areaOffice": 5,
        "areaOfficeEmail": "Area5@cbe.ab.ca",
        "areaOfficePhone": "403-777-8412",
        "principal": "Kat Siska",
        "principalEmail": "kasiska@cbe.ab.ca"
    },
    "Woodbine School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Brenna Fraser",
        "principalEmail": "woodbine@cbe.ab.ca"
    },
    "Woodlands School": {
        "ward": 13,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Josh Miller",
        "principalEmail": "woodlands@cbe.ab.ca"
    },
    "Woodman School": {
        "ward": 11,
        "trustee": "Nancy Close",
        "trusteeEmail": "neclose@cbe.ab.ca",
        "trusteePhone": "403-817-7918",
        "areaOffice": 6,
        "areaOfficeEmail": "Area6@cbe.ab.ca",
        "areaOfficePhone": "403-777-8780",
        "principal": "Marlene Krickhan",
        "principalEmail": "mlkrickhan@cbe.ab.ca"
    }
}
